<script>
  import FormSelect from "./FormSelect";

  export default {
    mixins: [ FormSelect ],

    data() {
      return {
        value: '',
        options: [
          {
            label: this.$t('low'),
            value: 'L'
          },
          {
            label: this.$t('medium'),
            value: 'M'
          },
          {
            label: this.$t('high'),
            value: 'H'
          }
        ]
      }
    }
  }
</script>

<i18n>
  {
    "es": {
      "low": "Baja",
      "medium": "Media",
      "high": "Alta"
    }
  }
</i18n>
