<script>
    import FormInput from "./FormInput";

    export default {
      mixins: [ FormInput ],

      props: {
        type:{
          type: String,
          default: 'number'
        }
      }
    }
</script>
