<script>
  import FormSelect from "./FormSelect";
  import http from "@/http/client";

  export default {
    mixins: [ FormSelect ],

    props: {
      selectLabel: {
        type: String,
        default: 'name'
      },
      trackBy: {
        type: String,
        default: 'uuid'
      }
    },

    methods: {
      load() {
        this.loading(this.$t('loading'));

        http
          .get('/garments')
          .then((response) => this.options = response.data)
          .finally(() => this.loaded())
      }
    },

    mounted() {
      this.load()
    }
  }
</script>

<i18n>
  {
    "es": {
      "loading": "Cargando prendas..."
    }
  }
</i18n>
