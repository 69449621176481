<script>
  import FormSelect from "./FormSelect";

  export default {
    mixins: [ FormSelect ],

    data() {
      return {
        value: '',
        options: [
          {
            label: this.$t('male'),
            value: 'M'
          },
          {
            label: this.$t('female'),
            value: 'F'
          },
          {
            label: this.$t('other'),
            value: 'U'
          }
        ]
      }
    }
  }
</script>

<i18n>
  {
    "es": {
      "male": "Hombre",
      "female": "Mujer",
      "other": "Otro"
    }
  }
</i18n>
