<template lang="pug">
  .form-group
    label(:for="idComplete" v-if="label") {{ label }}:
    textarea.form-control(
      v-model="model.$model"
      :id="idComplete"
      :class="classes"
      :rows="rows"
      :placeholder="statePlaceholder"
    )
    form-input-error-message(:field="model")
</template>

<script>
    import FormInput from "./FormInput";

    export default {
        mixins: [ FormInput ],

        props: {
          rows:{
            type: Number,
            default: 3
          }
        }
    }
</script>
