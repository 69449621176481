<script>
import FormSelect from "./FormSelect";

export default {
  mixins: [ FormSelect ],

  data() {
    return {
      value: '',
      options: [
        {
          label: '1.0.0 (nov. 2020)',
          value: '100'
        }
      ]
    }
  }
}
</script>
