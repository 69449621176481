<template lang="pug">
    div
      label(:for="idComplete" v-if="label") {{ label }}:
      .input-group
        input.form-control(
          v-model="model.$model"
          :class="classes"
          :id="idComplete"
          :type="type"
          :disabled="!enabled"
          :placeholder="statePlaceholder"
          @input="model.$reset()"
          @blur="model.$touch()"
        )

        .input-group-append
          slot(name="append-text")
            span.input-group-text {{ appendText }}

        form-input-error-message(:field="model")

      small.form-text.text-muted(v-if="help") {{ help }}
</template>

<script>
import FormInput from "./FormInput";

export default {
  mixins: [ FormInput ],

  props: {
    appendText: {
      type: String,
      default: ''
    }
  }
}
</script>
