<template lang="pug">
  img(:src="url")
</template>

<script>
export default {
  props: {
    baseUrl: {
      type: String,
      default: process.env.VUE_APP_BASE_URL
    },
    bucket: {
      type: String,
      default: null
    },
    uuid: {
      type: String,
      default: null
    }
  },

  data() {
    return {}
  },

  computed: {
    url() {
      return `${this.baseUrl}/app/image/${this.bucket}/${this.uuid}`
    }
  }
}
</script>
