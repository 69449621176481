<template lang="pug">
  div.form-group
    label(for="idComplete") {{ label }}:
    select.custom-select(
      v-model="model.$model"
      :id="idComplete"
      :class="classes"
      :disabled="!stateEnabled"
    )
      option(v-if="statePlaceholder" selected hidden :value="null") {{ statePlaceholder }}
      option(v-for="option in options" :value="option" :selected="isSelected(option)") {{ option[selectLabel] }}

    form-input-error-message(:field="model")
    small.form-text.text-muted(v-if="help") {{ help }}
</template>

<script>
import FormInput from "./FormInput";

export default {
  mixins: [ FormInput ],

  props: {
    selectLabel: {
      type: String,
      default: 'label'
    },
    trackBy: {
      type: String,
      default: 'value'
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      options: [],
      statePlaceholder: this.placeholder
    }
  },

  methods: {
    isSelected(option) {
      const value = option[this.trackBy];
      return null !== this.model.$model && (value == this.model.$model[this.trackBy] || value == this.model.$model);
    }
  },

  updated() {
    this.options.forEach((option) => {
      if(!this.isSelected(option)) {
        return;
      }

      this.model.$model = option;
    });
  }
}
</script>
