<template lang="pug">
  .form-check
    input.form-check-input(type="checkbox" :id="idComplete" :disabled="!stateEnabled" v-model="model.$model")
    label.form-check-label {{ label }}
</template>

<script>
import FormInput from "./FormInput";

export default {
  mixins: [ FormInput ]
}
</script>
