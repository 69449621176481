<template lang="pug">
  div.form-group
    div.input-group(:class="classes" )
      label(:for="idComplete") {{ label }}:

      .custom-file(style="width: 100%;")
        input(
          type="file"
          class="custom-file-input"
          :class="classes"
          :id="idComplete"
          :disabled="!stateEnabled"
          @change="onChange"
        )

        label.custom-file-label(:for="idComplete" :data-browse="searchText") {{ statePlaceholder }}
          span(v-if="model.$model") {{ model.$model.name }}

    form-input-error-message(:field="model")
</template>

<script>
import FormInput from "./FormInput";

export default {
  mixins: [ FormInput ],

  props: {
    searchText: {
      type: String,
      default: null
    }
  },

  methods: {
    onChange(event) {
      this.model.$model = event.target.files[0];
    }
  }
}
</script>
